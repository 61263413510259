<template>
    <div>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <!-- Card stats -->
        </base-header>

        <b-container fluid class="mt--7">
            <b-row>
                <b-col>
                    <b-overlay :show="loading" rounded="md">
                        <b-card no-body>
                            <b-card-header class="border-1">
                                <h3 class="mb-0">タスク：{{ task.name }}</h3>
                            </b-card-header>
                            <b-card-body>
                                <div>
                                    <h6 class="heading-small text-muted mb-4">タスク内容</h6>
                                    <template v-if="task.workflow === '12'">
                                        <AttendanceViewFields
                                            :openEditModal="openEditModal"
                                            :showEditBtn="showEditBtn" :editor="editor" :editorTime="editor_time" :task="task" :fields="fields"
                                            :fields_value="fields_value" />
                                    </template>
                                    <template v-else-if="task.workflow === '13' || task.workflow === '14'">
                                        <AssetsViewFields :task="task" :fields="fields" />
                                    </template>
                                    <template v-else>
                                        <ViewFields :task="task" :fields="fields" />
                                    </template>
                                    <hr class="my-4">
                                    <h6 class="heading-small text-muted mb-4">処理状況</h6>
                                    <flow-chart :task="task" :nodes="nodes">
                                    </flow-chart>
                                </div>
                            </b-card-body>
                            <b-card-footer>
                                <b-row>
                                    <b-col cols="12" class="d-flex justify-content-between">
                                        <span></span>
                                        <span>
                      <b-button variant="dark" :to="{ name: back_path }">戻る</b-button>
                    </span>
                                    </b-col>
                                </b-row>
                            </b-card-footer>
                        </b-card>
                    </b-overlay>
                </b-col>
            </b-row>

            <b-modal ref="edit-details-modal" title="編集" size="xl" @ok="handleStatisticsModalConfirm">
                <b-container>
                    <b-row>
                        <b-col v-for="(field, key) in statistics" :key="key" md="2">
                            <b-form-group :label="field.label" label-size="sm">
                                <b-form-input v-model="field.value"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
            </b-modal>

        </b-container>
    </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
import { helper, ViewFields } from '../common';
import { AttendanceViewFields } from '../common';
import { AssetsViewFields } from '../common';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        ViewFields,
        AttendanceViewFields,
        AssetsViewFields,
    },
    data() {
        return {
            loading: true,
            task: {},
            fields: [],
            nodes: [],
            fields_value: {},
            editor: null,
            editor_time: null,
            editModalOpen: false, // 控制模态框的显示状态
            statistics: {
                attendance_days_3: {
                    label: '出勤日数',
                    value: ''
                },
                attendance_time_3: {
                    label: '出勤日数算出',
                    value: ''
                },
                total_work_time_3: {
                    label: '総就業時間',
                    value: ''
                },
                total_early_time_3: {
                    label: '普通残業時間',
                    value: ''
                },
                over_time_3: {
                    label: '普通残業時間算出',
                    value: ''
                },
                total_night_time_3: {
                    label: '深夜残業時間',
                    value: ''
                },
                legal_holiday_3: {
                    label: '法定休日出勤日数',
                    value: ''
                },
                legal_holiday_time_3: {
                    label: '法定休日出勤時間',
                    value: ''
                },
                legal_hol_over_3: {
                    label: '法定休日残業時間',
                    value: ''
                },
                legal_hol_night_3: {
                    label: '法定休日深夜残業時間',
                    value: ''
                },
                compensatory_days_3: {
                    label: '代休取得日数',
                    value: ''
                },
                compensatory_leave_3: {
                    label: '振替取得日数',
                    value: ''
                },
                nonlegal_hol_3: {
                    label: '法定外休日出勤日数',
                    value: ''
                },
                nonlegal_hol_time_3: {
                    label: '法定外休日出勤時間',
                    value: ''
                },
                nonlegal_hol_over_3: {
                    label: '法定外休日残業時間',
                    value: ''
                },
                nonlegal_hol_night_3: {
                    label: '法定外休日深夜残業時間',
                    value: ''
                },
                paid_vacation_days_3: {
                    label: '有休取得日数',
                    value: ''
                },
                paid_vacation_time_3: {
                    label: '有休時間',
                    value: ''
                },
                absence_days_3: {
                    label: '欠勤日数',
                    value: ''
                },
                absence_calculate_3: {
                    label: '欠勤日数算出',
                    value: ''
                },
                absence_time_3: {
                    label: '欠勤時間',
                    value: ''
                },
                excess_over_3: {
                    label: '超過残業時間',
                    value: ''
                },
                amount_time_3: {
                    label: '総合計時間',
                    value: ''
                }
            },
            showEditBtn: false
        };
    },
    watch: {
        $route(to, from) {  // eslint-disable-line
            this.getTaskDetail(this.task_id);
        },
        organizationsMap(newValue, oldValue) {
            console.log('oldValue', oldValue)
            if(newValue) {
                let org_full_name = newValue.get(this.task.org_id)
                this.task = {...this.task, org_full_name,}
            }
        }
    },
    updated: function () {
        document.title = `${this.task.name} | ${common.getPageName(this.$route.name)}`;
    },
    created: function () {
        this.getTaskDetail(this.task_id);
    },
    computed: {
        ...mapState('account', [ 'organizationsMap', ]),
        task_id: function () {
            return this.$route.params.task_id;
        },
        back_path: function () {
            return 'tasks-observer';
        }
    },
    methods: {
        ...mapActions('account', ['getStatistics']),

        openEditModal() {
            // 父组件的方法逻辑
            this.$refs['edit-details-modal'].show()
        },
        handleStatisticsModalConfirm(bvModalEvent) {
            bvModalEvent.preventDefault()
            common.loading()
            const params = {}
            params['year'] = this.fields_value.year
            params['month'] = this.fields_value.month
            params['org_id'] = this.task.org_id
            params['basic_attendance_day'] = this.fields_value.basic_attendance_day

            const keys = Object.keys(this.statistics)
            keys.forEach(k => {
                params[k] = this.statistics[k].value
            })
            ajax.fetchPost(common.formatStr(config.api.attendance.modify_details, this.task_id), params).then(() => {
                this.getTaskDetail(this.task_id)
            }).finally(() => {

                this.$refs['edit-details-modal'].hide()
            })
        },
        showUpdateDt: function (d) {
            return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm');
        },

        getTaskDetail: function (id) {
            console.log('organizationsMap', this.organizationsMap)
            const vm = this;
            ajax.fetchGet(common.formatStr(config.api.task.task_detail, id)).then(data => {
                vm.task = data;
            })
                .then(async () => {
                    const _data = await ajax.fetchGet(common.formatStr(config.api.employee.v2_employee_detail, vm.task.employee)).catch(err => console.log(err)) || {};
                    let full_org_name ="";
                    if(this.organizationsMap.get) {
                        full_org_name = this.organizationsMap.get(vm.task.org_id)
                    }
                    vm.task = {...vm.task , employee_code : _data.code || "退職済み", org_full_name: full_org_name}
                })
                .then(async () => {
                    const data = await ajax.fetchGet(common.formatStr(config.api.task.task_form, id));
                    vm.fields = data;
                    //勤怠申請
                    if (vm.task.workflow === '12') {
                        vm.fields_value = helper.get_value_from_fields(data);
                        this.get_business_days(vm.fields_value.year, vm.fields_value.month )
                    }
                }).then(async () => {
                const data = await ajax.fetchGet(common.formatStr(config.api.task.task_nodes, id));
                vm.nodes = data;
            })
                .then(async () => {
                    console.log(vm.task.workflow)
                    if (vm.task.workflow === '12') {
                        const data = await ajax.fetchGet(common.formatStr(config.api.attendance.attendance_details, id))
                        const keys = Object.keys(data)
                        if (keys.length > 0) {
                            vm.editor = data['editor_name']
                            vm.editor_time = this.showUpdateDt(data['updated_dt'])
                            keys.forEach(k => {
                                if (vm.fields_value[k]) {
                                    vm.fields_value[k] = data[k]
                                }
                            })
                        }
                    }

                }).then(async () => {
                const data = await ajax.fetchGet(common.formatStr(config.api.attendance.attendance_details, id))
                const keys = Object.keys(data)
                if (keys.length > 0) {
                    this.editor = data['editor_name']
                    this.editor_time = this.showUpdateDt(data['updated_dt'])
                    keys.forEach(k => {
                        if (this.statistics[k]) {
                            this.statistics[k].value = data[k]
                        }
                        if (vm.fields_value[k]) {
                            vm.fields_value[k] = data[k]
                        }
                    })
                } else {
                    const keys = Object.keys(vm.fields_value)
                    keys.forEach(k => {
                        if (this.statistics[k]) {
                            this.statistics[k].value = vm.fields_value[k]
                        }
                    })
                }
            })
                .finally(() => {
                    common.loaded()
                    vm.loading = false;
                });
        },
        get_business_days(year, month) {
            const vm = this;
            ajax.fetchGet(common.formatStr(config.api.task.business_days, year, month)).then(data => {
                vm.$set(vm.fields_value, 'basic_attendance_day', data.length);
            }).catch(errors => {
                helper.show_errors(errors);
            });
        },
    },
}
</script>